import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import './ModalTable.css';

const ModalTable = ({ isOpen, onRequestClose, title, data, nestedKitdata }) => {
  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // const toggleExpand = (rowIndex) => {
  //   setExpandedRows((prev) => ({ ...prev, [rowIndex]: !prev[rowIndex] }));
  // };

  useEffect(() => {
    setItems(data);
    const fieldNames = Object.keys(data[0]).filter((key) => key !== 'subItems' && key !== 'id' && key !== 'itemKitTemplateId'); // Exclude specific columns

    const columns = [
      ...fieldNames.map((field) => ({
        name: capitalizeFirstLetter(field),
        selector: (row) => row[field],
        sortable: true,
        cell: (row) => <div title={row[field]}>{row[field]}</div>,
      })),
    ];

    setColumns(columns);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onRequestClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onRequestClose, expandedRows]);

  // Dynamically create columns for sub-items based on fields
  const getSubItemColumns = (subItems) => {
    if (!subItems || subItems.length === 0) return [];

  // Filter out unwanted columns
  const subItemFields = Object.keys(subItems[0]).filter((key) => key !== 'id' && key !== 'itemId'); // Exclude 'id' and 'itemId'

  // Move 'sort' to the beginning of the columns array
  const reorderedFields = ['sort', ...subItemFields.filter((key) => key !== 'sort')];

  return reorderedFields.map((field) => ({
    name: capitalizeFirstLetter(field),
    selector: (row) => row[field],
    sortable: true,
    cell: (row) => <div title={row[field]}>{row[field]}</div>,
  }));
};

  // ExpandedComponent for each row’s sub-items table
  const ExpandedComponent = ({ data }) => {

    // Check if nestedKitdata exists and is valid
  if (!nestedKitdata || !Array.isArray(nestedKitdata)) {
    return <div>

    </div>;
  }
    const rowNestedData = nestedKitdata.find((item) => item.id === data.id)?.nestedData || []; // Find matching nested data

    // Sort the nested data by 'sort' column
    const sortedData = rowNestedData.slice().sort((a, b) => a.sort - b.sort);

    if(data.itemKitTemplateId == 0 || rowNestedData.length === 0){
        return (<div>
            
        </div>)
    }
    return (
      <div style={{ padding: '10px 20px', borderTop: '1px solid #ccc' }}>
        <p>Items in {data.name}</p>
        <DataTable
          columns={getSubItemColumns(rowNestedData)} // Dynamically generate sub-item columns
          data={sortedData} // Render sub-items specific to the row
          noHeader
          pagination={false}
          highlightOnHover
          striped
        />
      </div>
    );
  };


  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {title && <h2 className="modal-title">{title}</h2>}
        <div className="modal-body">
        <DataTable
          columns={columns}
          data={items}
          pagination
          highlightOnHover
          striped
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
        </div>
        <button onClick={onRequestClose} className="close-button">Close</button>
      </div>
    </div>
  );
};

export default ModalTable;
