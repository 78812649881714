const validater = (errors, values, store) => {
  var letters = /^[a-zA-Z][a-zA-Z ]*$/;
  if (
  (!Number.isInteger(values?.lowInventoryThreshold) && 
  values?.lowInventoryThreshold !== undefined && 
  values?.lowInventoryThreshold !== '') || 
  (values?.lowInventoryThreshold > 10000)
) {
  errors.lowInventoryThreshold = 
    values?.lowInventoryThreshold > 10000 
      ? 'Value cannot exceed 10,000' 
      : 'Invalid quantity';
}

  if (values?.name?.length > 100) {
    errors.name = 'max 100 character allowed';
  }
  if (values?.description?.length > 250) {
    errors.description = 'max 250 character allowed';
  }
  if (!values?.firstName?.match(letters) && values?.firstName) {
    errors.firstName = 'Enter only Alphabet characters';
  }
  if (!values?.lastName?.match(letters) && values?.lastName) {
    errors.lastName = 'Enter only Alphabet characters';
  }
  if (values?.fileId?.size > 2000000 && values?.fileId !== undefined) {
    errors.fileId = 'image size must be less then 2MB';
  }
  if (values?.unitSize?.length > 10 && values?.unitSize !== undefined) {
    errors.unitSize = 'Max 10 characters allowed';
  }

  return errors;
};

export default validater;
