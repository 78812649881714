import React, {useState} from 'react';

const FormCheckbox = (props) => {

  // Actually, we don’t use these two lines; they’re just there to prevent the warning about needing an `onChange` method for a controlled field. We can refine this approach and follow best practices later.
  const [isChecked, setIsChecked] = useState(false)
  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };


  return <input {...props} className={`w-[20px] h-[20px] ${props.className}   border-solid p-[9px] font-medium text-[12px] leading-[15px] border rounded-[5px]`} onChange={props?.onChange ? props?.onChange: handleChange}/>;
};
export default FormCheckbox;
