import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert as AlertBootstrap } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Skeleton from "react-loading-skeleton";
import NavDropdown from "react-bootstrap/NavDropdown";
import { getItemCategoryByIdAction } from "../Actions/itemCategory";
import HemaHeadingWithSubText from "../utils/HemaHeadingWithSubText";
import {
  Button,
  FormSearch,
  HemaLabel,
  HemaValue,
  Alert,
  Pagination,
  NoPermissionAlert,
} from "../utils";
import { Remove } from "../components/Formik/AllForms/remove";
import {
  Add,
  ExpiryAlertPurple,
  AddInventoryPurple,
  CreateItemPurple,
  DeletePurple,
  CreateTranslation,
  Edit,
  Dust,
  Assemblies,
  DotsIcon,
  Confirm,
  DeleteIcon,
  TranslateIcon,
} from "../HemeIconLibrary";
import { buildNewKitAction } from "../Actions/kitBuilder";
import FormContainer from "../components/Formik/formContainer";
import {
  setForm,
  editFormReducer,
  setFormCloseReducer,
  showSuccessReducer,
  setFormLoaderReducer,
} from "../Store/reducers/uiSettings";
import { getKitBuilderAction } from "../Actions/kitBuilder";
import { getStorageLocationTreeAction } from "../Actions/storageLocation";
import {
  getAllItemAction,
  createNewItemAction,
  uploadFileItemAction,
  addLanguageForItemAction,
  addItemInventoryAction,
  editLanguageForItemAction,
  deleteNewItemAction,
  deleteItemsLanguageAction,
  updateNewItemAction,
  getItemByIdAction,
} from "../Actions/inventory";
import {
  getTypesAction,
  getAllItemCategoriesAction,
  getAllInventoryLanguages,
} from "../Actions/common";
import { quantityChcek } from "../components/Formik/allFormValidation";
import { setThresholdReducer } from "../Store/reducers/common";
import { createNewItem } from "../components/Formik/AllForms/createNewItem";
import { addNewInventory } from "../components/Formik/AllForms/addInventory";
import { createNewItemVersion } from "../components/Formik/AllForms/createItemTranslation";
import { addNewInventoryCheck } from "../components/Formik/AllForms/addInventoryCheck";
import createNewItemValidator from "../components/Formik/allFormValidation/createItem";

import { InventoryItemColumns } from "../components/Tables";

import { getType } from "../helpers/getType";
import { filter } from "smart-array-filter";
import { getSystemUserAction } from "../Actions/settings";

const Inventory = () => {
  const dispatch = useDispatch();
  const { uisettings, allItems, common, builder, settings, storageLocation } =
    useSelector((state) => state);
  const [updatedData, setUpdatedData] = useState();
  const [searchQuery, setsearchQuery] = React.useState("");
  // const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filteredData, setfilteredData] = useState(null);

  useEffect(() => {
    (async () => {
      if (allItems?.allItems) {
        if (searchQuery) {
          const searchResult = filter(allItems?.allItems, {
            keywords: searchQuery, // search for any field that contains the "Do" string

            caseSensitive: false,
          });

          setfilteredData(searchResult);
        } else {
          setfilteredData(allItems?.allItems || []);
        }
      }
    })();
  }, [searchQuery, allItems?.allItems]);

  // useEffect(() => {
  //   getAllItemAction();
  // }, [allItems]);

  const [CTA, setCTA] = useState();
  const [formName, setformName] = useState();
  const [formIcon, setFormIcon] = useState();
  const [formValidation, setFormValidation] = useState();
  const [okBtnText, setokBtnText] = useState();
  const [okBtnIcon, setokBtnIcon] = useState();
  const [cancelBtnText, setCancelBtnText] = useState();
  const [cancelBtnIcon, setCancelBtnIcon] = useState();

  //callallEnventoryRequiredAPI
  useEffect(() => {
    getTypesAction();
    getAllItemCategoriesAction();
    getAllItemAction();
    getAllInventoryLanguages();
    getKitBuilderAction();
    getStorageLocationTreeAction();
    getSystemUserAction();
  }, []);

  // control item thresold switch
  useEffect(() => {
    if (common?.switch?.threshold) {
      //  if (uisettings?.formName === 'additem') {
      createNewItem[7].required = true;
      createNewItem[7].disabled = false;
      createNewItem[7].initialValue = "";
      createNewItem[8].disabled = false;
      createNewItem[8].required = true;
      //  }
      //   if (uisettings?.formName === 'addInventory') {
      addNewInventory[3].required = true;
      addNewInventory[8].required = true;
      addNewInventory[8].initialValue = "";

      addNewInventory[8].disabled = false;
      addNewInventory[9].disabled = false;
      addNewInventory[9].initialValue = [];
      addNewInventory[9].required = true;
      //   }
    } else {
      //   if (uisettings?.formName === 'additem') {
      createNewItem[7].initialValue = undefined;
      createNewItem[7].required = false;
      createNewItem[7].disabled = true;
      createNewItem[8].disabled = true;
      createNewItem[8].required = false;
      createNewItem[8].initialValue = [];
      //   }
      //    if (uisettings?.formName === 'addInventory') {
      if (
        !storageLocation?.itemCategories?.fields?.filter(
          (data) => data.name === "Expiration Date" && data.isRequired
        )?.length
      ) {
        addNewInventory[3].required = false;
      }
      addNewInventory[8].required = false;
      addNewInventory[8].initialValue = undefined;
      addNewInventory[3].required = false;
      addNewInventory[8].disabled = true;

      addNewInventory[9].initialValue = [];
      addNewInventory[9].disabled = true;
      addNewInventory[9].required = false;
      //    }
    }
  }, [common.switch]);

  // useEffect(() => {
  //   if (common.activeDropdownValue.name === 'itemCategoryId') {
  //     createNewItem[2].required =
  //       common.activeDropdownValue.id === 1 ? true : false;
  //   }
  // }, [common.activeDropdownValue.id]);

  // CTA item add
  const addInventoryCTA = (edit) => {
    setCTA(() => async (data) => {
      if (edit) {
        dispatch(setFormLoaderReducer(true));
        const imageToUpload = data.fileId;
        let payload = JSON.parse(JSON.stringify(data));
        payload.fileId = undefined;
        const editIem = await updateNewItemAction(edit, {
          ...payload,
          lowInventoryThreshold: !payload?.lowInventoryAlert
            ? undefined
            : payload.lowInventoryThreshold,
          userIds: !payload?.lowInventoryAlert
            ? undefined
            : payload?.userIds?.map((data) => data.id),
          unitTypeId: payload?.unitTypeId ? payload.unitTypeId : undefined,
        });
        if (editIem?.data?.id) {
          if (imageToUpload) {
            const imageCreation = await uploadFileItemAction(
              editIem.data?.id,
              imageToUpload
            );
            // if (imageCreation.data.id) {
            dispatch(setFormCloseReducer());
            // }
          } else {
            dispatch(setFormCloseReducer());
          }
        }
        dispatch(setFormLoaderReducer(false));
        dispatch(showSuccessReducer(`${editIem.data.name} item updated.`));
        getAllItemAction();
      } else {
        dispatch(setFormLoaderReducer(true));
        const imageToUpload = data.fileId;
        let payload = JSON.parse(JSON.stringify(data));
        payload.fileId = undefined;
        const createNewIem = await createNewItemAction({
          ...payload,
          lowInventoryThreshold: !payload?.lowInventoryAlert
            ? undefined
            : payload.lowInventoryThreshold,
          userIds: !payload?.lowInventoryAlert
            ? undefined
            : payload?.userIds?.map((data) => data.id),

          unitTypeId: payload?.unitTypeId ? payload.unitTypeId : undefined,
        });
        if (createNewIem?.status === 200) {
          if (imageToUpload) {
            const imageCreation = await uploadFileItemAction(
              createNewIem.data?.id,
              imageToUpload
            );
            // if (imageCreation.data.id) {
            //dispatch(setFormCloseReducer());
            // }
          }
          // else {
          //   dispatch(setFormCloseReducer());
          // }
          dispatch(
            showSuccessReducer(`${createNewIem.data.name} item created.`)
          );
          getAllItemAction();
          setformName("Add Inventory");
          setFormIcon(<AddInventoryPurple />);
          setokBtnText("Yes");
          setCancelBtnText("No");
          dispatch(setThresholdReducer(false));
          setUpdatedData([
            {
              label:
                "Would you like to add inventory to the item you just created?",
              initialValue: "",
              name: "inventory_confirm",
              fluid: true,
              type: "",
              noCapital: true,
            },
          ]);
          setCTA(() => async (data) => {
            const result = await getItemCategoryByIdAction(
              createNewIem?.data?.itemCategory?.id
            );

            var customEnventory = [];
            result.data?.fields?.forEach((field, index) => {
              var obj = {};
              if (!field.isStandard) {
                obj.type = getType(field.format?.name);
                obj.name = field.id || undefined;
                obj.label = field.name || undefined;
                obj.initialValue = field.value || undefined;
                obj.required = field.isRequired || false;
                obj.placeholder = field.placeholder || field.name;
                obj.options = field.options || [];
                customEnventory.push(obj);
              }
            });

            addNewInventory[0].initialValue = createNewIem?.data.id;
            addNewInventory[0].placeholder = createNewIem?.data.name;
            addNewInventory[0].disabled = true;
            addNewInventory[7].initialValue = false;

            addNewInventory[4].required =
              result.data?.fields?.filter(
                (data) => data.mergeFieldKey === "SerialNumber"
              )?.[0]?.isRequired || false;

            addNewInventory[5].required =
              result.data?.fields?.filter(
                (data) => data.mergeFieldKey === "Barcode"
              )?.[0]?.isRequired || false;

            addNewInventory[7].required =
              result.data?.fields?.filter(
                (data) => data.mergeFieldKey === "CloseToExpiryAlert"
              )?.[0]?.isRequired || false;
            addNewInventory[7].initialValue =
              result.data?.fields?.filter(
                (data) => data.mergeFieldKey === "CloseToExpiryAlert"
              )?.[0]?.isRequired || false;
            addNewInventory[7].disabled =
              !result.data?.fields?.filter(
                (data) => data.mergeFieldKey === "CloseToExpiryAlert"
              )?.[0]?.isRequired || false;

            addNewInventory[8].required =
              result.data?.fields?.filter(
                (data) => data.mergeFieldKey === "CloseToExpiryAlert"
              )?.[0]?.isRequired || false;
            addNewInventory[8].disabled =
              !result.data?.fields?.filter(
                (data) => data.mergeFieldKey === "CloseToExpiryAlert"
              )?.[0]?.isRequired || false;

            addNewInventory[3].required =
              result.data?.fields?.filter(
                (data) => data.mergeFieldKey === "CloseToExpiryAlert"
              )?.[0]?.isRequired || false;
            // addNewInventory[9].initialValue = ''
            addNewInventory[9].options = settings?.systemUsers
              ?.filter((active) => active.isActive)
              ?.map((data) => {
                return {
                  id: data.id,
                  name: data.firstName + " " + data.lastName,
                };
              });

            setokBtnText("Save");
            setCancelBtnText("Cancel");
            setUpdatedData([...addNewInventory, ...customEnventory]);
            addInventoryForItemCTA();
            dispatch(setForm({ state: true, type: "addInventory" }));
          });
        }

        dispatch(setFormLoaderReducer(false));
      }
    });
    setFormValidation((error, values) => (error, values) => {
      createNewItemValidator(error, values);
    });
  };

  // CTA Item verison
  const addVersionCTA = (id, type) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      let createNewIemVerison;
      if (type === "edit") {
        createNewIemVerison = await editLanguageForItemAction(id, data);
      } else {
        createNewIemVerison = await addLanguageForItemAction(id, data);
      }
      if (createNewIemVerison?.data?.id) {
        dispatch(setFormCloseReducer());
        dispatch(
          showSuccessReducer(
            `${createNewIemVerison.data.name} ${
              type === "edit" ? "version updated." : "version created."
            }`
          )
        );
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  // CTA for inventory for selected item
  const addInventoryForItemCTA = () => {
    setCTA(() => async (id, data, values) => {
      dispatch(setFormLoaderReducer(true));
      delete data.StorageLocation;
      const createNewIem = await addItemInventoryAction(id, {
        fields: data,
        userIds: !values?.CloseToExpiryAlert
          ? undefined
          : values?.userIds?.map((d) => d.id) || [],
      });
      if (createNewIem?.data?.id) {
        getAllItemAction();
        dispatch(setFormCloseReducer());
        dispatch(
          showSuccessReducer(
            `${createNewIem.data.quantity} ${createNewIem.data.item?.name} added.`
          )
        );
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  //CTA for remove

  const deleteInventoryForItemCTA = async (id, name, reason) => {
    dispatch(setFormLoaderReducer(true));
    const deleteNewIem = await deleteNewItemAction(id, reason);
    if (deleteNewIem?.status === 200) {
      dispatch(
        setForm({
          state: false,
          type: "",
        })
      );
      dispatch(showSuccessReducer(`${name} deleted.`));
    }
    dispatch(setFormLoaderReducer(false));
  };

  const deleteLanguageForItemCTA = async (
    name,
    itemName,
    itemId,
    langId,
    change_reason
  ) => {
    const deleteLang = await deleteItemsLanguageAction(
      itemId,
      langId,
      change_reason
    );
    if (deleteLang?.status === 200) {
      dispatch(setFormLoaderReducer(false));
      dispatch(
        setForm({
          state: false,
          type: "",
        })
      );
      dispatch(
        showSuccessReducer(`${name} translation deleted for ${itemName}.`)
      );
    }
  };

  const addkitTemplate = async (data, store) => {
    dispatch(setFormLoaderReducer(true));
    const { uisettings, builder } = store;
    const createNewIem = await buildNewKitAction(uisettings.editForm?.kitId, {
      qty: uisettings.editForm?.selectedQuantity,
      storageLocationId: data?.locationId?.id,
      closeToExpiryAlert: data.closeToExpiryAlert,
      closeToExpiryDays: data.closeToExpiryAlert
        ? data.closeToExpiryNumberOfDays
        : undefined,
      items: builder.buildKit?.items?.map((item) => {
        return item.lots.map((lot, counter) => {
          if (counter === 0) {
            return { id: lot.id };
          }
        })?.[0];
      }),
    });
    if (createNewIem?.status === 200) {
      dispatch(setFormCloseReducer());
      dispatch(
        showSuccessReducer(
          `${createNewIem?.data?.quantity} ${createNewIem?.data?.item?.name} Kit added.`
        )
      );
    }
    dispatch(setFormLoaderReducer(false));
  };

  const callCreateNewItem = () => {
    createNewItem[4].options = common.allTypes;
    createNewItem[1].options = common.allCategories;
    createNewItem[0].disabled = false;
    createNewItem[1].disabled = false;
    createNewItem[0].initialValue = "";
    createNewItem[1].initialValue = "";
    createNewItem[2].initialValue = "";
    createNewItem[2].required = false;
    createNewItem[3].storageUrl = "";
    createNewItem[5].initialValue = "";
    createNewItem[1].placeholder = "Select category";
    createNewItem[4].placeholder = "Select unit Type";
    createNewItem[4].initialValue = "";
    createNewItem[6].initialValue = false;
    createNewItem[7].initialValue = "";
    createNewItem[8].initialValue = [];
    createNewItem[7].disabled = true;
    createNewItem[8].disabled = true;
    createNewItem[8].options = settings.systemUsers
      ?.filter((active) => active.isActive)
      ?.map((data) => {
        return { id: data.id, name: data.firstName + " " + data.lastName };
      });
    setformName("Create New Item");
    setFormIcon(<CreateItemPurple />);
    setokBtnText("Save");
    setokBtnIcon(<Confirm />);
    setCancelBtnText("Cancel");
    // createNewItem = createNewItem?.filter((item) => item.name !== "change_reason");
    setUpdatedData(
      createNewItem?.filter((item) => item.name !== "change_reason")
    );
    addInventoryCTA();
    dispatch(setForm({ state: true, type: "additem" }));
  };

  if (!common.permissions?.["Inventory Management"]?.read) {
    return <NoPermissionAlert />;
  }

  return (
    <>
      <div className="flex gap-[10px] justify-between items-center">
        <HemaHeadingWithSubText
          heading="Inventory Management"
          sub="Manage your inventory here."
        />
        {common?.permissions?.["Inventory Management"]?.write && (
          <div className="flex gap-[10px] ">
            {true ? (
              <Button
                text="Add Inventory"
                icon={<Add color="white" />}
                color="text-white"
                bg="bg-primary1"
                cta={() => {
                  addNewInventoryCheck[0].options = allItems?.allItems;
                  addNewInventoryCheck[0].disabled = false;
                  addNewInventoryCheck[0].placeholder = "Select an item";
                  addNewInventoryCheck[0].initialValue = "";
                  addNewInventory[7].initialValue = false;
                  addNewInventory[8].disabled = true;

                  addNewInventory[8].required = false;
                  addNewInventory[3].required = false;

                  setCTA(() => async (data) => {
                    const itemFound = allItems?.allItems.filter(
                      (item) => item.id === data?.itemName
                    )?.[0];
                    if (itemFound) {
                      if (itemFound.itemCategory?.name === "H-Kit") {
                        dispatch(editFormReducer(itemFound));
                        setformName(`Build Kit`);
                        setFormIcon(<AddInventoryPurple />);

                        dispatch(
                          setForm({
                            state: true,
                            type: "edit-kit-template",
                          })
                        );

                        setokBtnText("Build");
                      } else {
                        const result = await getItemCategoryByIdAction(
                          itemFound.itemCategory.id
                        );

                        var customEnventory = [];
                        result.data?.fields?.forEach((field, index) => {
                          var obj = {};
                          if (!field.isStandard) {
                            obj.type = getType(field.format?.name);
                            obj.name = field.id || undefined;
                            obj.label = field.name || undefined;
                            obj.initialValue = field.value || undefined;
                            obj.required = field.isRequired || false;
                            obj.placeholder = field.placeholder || field.name;
                            obj.options = field.options || [];
                            customEnventory.push(obj);
                          }
                        });

                        setformName("Add Inventory");
                        setFormIcon(<Assemblies purple />);
                        addNewInventory[0].initialValue = itemFound?.id;
                        addNewInventory[0].placeholder = itemFound.name;
                        addNewInventory[0].disabled = true;
                        addNewInventory[7].initialValue = false;
                        addNewInventory[8].disabled = true;
                        addNewInventory[3].required =
                          result.data?.fields?.filter(
                            (data) => data.mergeFieldKey === "ExpirationDate"
                          )?.[0]?.isRequired || false;

                        addNewInventory[4].required =
                          result.data?.fields?.filter(
                            (data) => data.mergeFieldKey === "SerialNumber"
                          )?.[0]?.isRequired || false;

                        addNewInventory[5].required =
                          result.data?.fields?.filter(
                            (data) => data.mergeFieldKey === "Barcode"
                          )?.[0]?.isRequired || false;

                        addNewInventory[7].required =
                          result.data?.fields?.filter(
                            (data) =>
                              data.mergeFieldKey === "CloseToExpiryAlert"
                          )?.[0]?.isRequired || false;
                        addNewInventory[7].initialValue =
                          result.data?.fields?.filter(
                            (data) =>
                              data.mergeFieldKey === "CloseToExpiryAlert"
                          )?.[0]?.isRequired || false;
                        addNewInventory[7].disabled =
                          !result.data?.fields?.filter(
                            (data) =>
                              data.mergeFieldKey === "CloseToExpiryAlert"
                          )?.[0]?.isRequired || false;

                        addNewInventory[8].required =
                          result.data?.fields?.filter(
                            (data) =>
                              data.mergeFieldKey === "CloseToExpiryAlert"
                          )?.[0]?.isRequired || false;
                        addNewInventory[8].disabled =
                          !result.data?.fields?.filter(
                            (data) =>
                              data.mergeFieldKey === "CloseToExpiryAlert"
                          )?.[0]?.isRequired || false;

                        addNewInventory[3].required =
                          result.data?.fields?.filter(
                            (data) => data.mergeFieldKey === "ExpirationDate"
                          )?.[0]?.isRequired || false;
                        addNewInventory[9].disabled = true;
                        addNewInventory[9].options = settings?.systemUsers
                          ?.filter((active) => active.isActive)
                          ?.map((data) => {
                            return {
                              id: data.id,
                              name: data.firstName + " " + data.lastName,
                            };
                          });
                        setUpdatedData([
                          ...addNewInventory,
                          ...customEnventory,
                        ]);
                        addInventoryForItemCTA();
                        dispatch(
                          setForm({ state: true, type: "addInventory" })
                        );
                      }
                    }
                  });
                  setformName("Add Inventory");
                  setFormIcon(<Assemblies purple />);

                  setUpdatedData(addNewInventoryCheck);
                  dispatch(
                    setForm({ state: true, type: "addInventory1stStep" })
                  );
                  // setFormValidation(() => (error, values, store) => {
                  //   negativeQuantityCheck(error, values);
                  // });
                  setokBtnIcon(<Add color="white" />);
                  setokBtnText("Add");
                }}
              />
            ) : (
              <></>
            )}
            {true && (
              <Button
                text="Create New Item"
                icon={<Add color="white" />}
                color="text-white"
                bg="bg-primary1"
                cta={() => {
                  dispatch(setThresholdReducer(false));
                  callCreateNewItem();
                }}
              />
            )}
          </div>
        )}
      </div>
      <Alert />
      <div className="bg-white rounded-[5px] px-[10px] py-[15px] mt-[27px] mb-[13px]">
        <FormSearch
          w="w-[400px]"
          searchQuery={searchQuery}
          setsearchQuery={setsearchQuery}
        />
      </div>
      {allItems?.allItems ? (
        filteredData?.length > 0 ? (
          <DataTable
            data={filteredData}
            className="inventory_table"
            defaultSortFieldId={1}
            columns={[
              {
                style: {
                  alignItems: "flex-start",
                },
                cell: (row) => {
                  return (
                    <div>
                      <div className="flex items-center gap-[11px] meta mb-[12px] table_lable_hed">
                        <HemaLabel
                          textSize="text-[12px]"
                          text="Item Name"
                          showCaret
                        />
                        <Link
                          className="no-underline text-[14px] leading-[21px] font-medium"
                          aria-disabled="true"
                          to={`/inventory/${row.id}`}
                        >
                          <HemaValue
                            text={row?.name || "--"}
                            color="text-[#775FD5]"
                          />
                        </Link>
                      </div>
                      <div className="flex items-center gap-[11px] meta mb-[12px] table_lable_hed">
                        <HemaLabel
                          text="Category"
                          textSize="text-[12px]"
                          showCaret
                        />
                        <HemaValue
                          text={row?.itemCategory?.name || "--"}
                          color="text-[#595959]"
                        />
                      </div>
                      <div className="flex items-start gap-[11px] meta mb-[12px] table_lable_hed">
                        <HemaLabel
                          text="Translation"
                          textSize="text-[12px]"
                          showCaret
                        />
                        <div className="flex items-center gap-[5px] flex-wrap">
                          {row.translations?.map((dataItem, index) => (
                            <>
                              <div className="flex cursor-pointer items-center gap-[4px] hover-language-icon-delete h-[22px]">
                                <HemaValue
                                  text={dataItem?.name}
                                  cta={() => {
                                    if (
                                      common?.permissions?.[
                                        "Inventory Management"
                                      ]?.update
                                    ) {
                                      dispatch(editFormReducer(row));
                                      createNewItemVersion[0].disabled = true;
                                      createNewItemVersion[0].initialValue =
                                        dataItem.id;
                                      createNewItemVersion[0].placeholder =
                                        dataItem.name;
                                      createNewItemVersion[1].initialValue =
                                        dataItem.itemName;
                                      createNewItemVersion[2].initialValue =
                                        dataItem.itemDescription;
                                      createNewItemVersion[2].placeholder =
                                        "Enter description";
                                      createNewItemVersion[2].required =
                                        row?.itemCategory?.name === "H-Kit"
                                          ? true
                                          : false;
                                      createNewItemVersion[3].disabled = true;
                                      createNewItemVersion[4].disabled = true;
                                      createNewItemVersion[5].disabled = true;
                                      createNewItemVersion[3].placeholder =
                                        row.itemCategory?.name;
                                      createNewItemVersion[3].initialValue =
                                        row.itemCategory?.id;
                                      createNewItemVersion[4].placeholder =
                                        row.unitType?.name;
                                      createNewItemVersion[5].initialValue =
                                        row.unitSize;
                                      setFormValidation(
                                        () => (errors, values) => {
                                          if (values?.name?.length > 100) {
                                            errors.name =
                                              "max 100 character allowed";
                                          }
                                          if (
                                            values?.description?.length > 500
                                          ) {
                                            errors.description =
                                              "max 500 character allowed";
                                          }
                                          return errors;
                                        }
                                      );
                                      setUpdatedData(createNewItemVersion);
                                      setformName("Update Translation");
                                      setFormIcon(<CreateItemPurple />);
                                      setokBtnText("Save");
                                      setCancelBtnText("Cancel");
                                      dispatch(
                                        setForm({
                                          state: true,
                                          type: "editversion",
                                        })
                                      );
                                      addVersionCTA(row.id, "edit");
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                  color="text-[#775FD5]"
                                />
                                {common?.permissions?.["Inventory Management"]
                                  ?.delete && (
                                  <div
                                    className="mx-[10px] hidden"
                                    onClick={() => {
                                      Remove[0].label = "Translation Name";
                                      Remove[0].initialValue = dataItem?.name;
                                      setformName("Delete Translation");
                                      setFormIcon(<DeletePurple />);
                                      setokBtnText("Confirm");
                                      setCancelBtnText("Cancel");
                                      dispatch(
                                        setForm({
                                          state: true,
                                          type: "deleteItem",
                                        })
                                      );
                                      setUpdatedData([
                                        {
                                          label: "Item Name",
                                          initialValue: row?.name,
                                          name: "Item Name",
                                          fluid: true,
                                          type: "table",
                                        },
                                        ...Remove,
                                      ]);
                                      setCTA(() => async (data) => {
                                        dispatch(setFormLoaderReducer(true));
                                        deleteLanguageForItemCTA(
                                          dataItem.name,
                                          row?.name,
                                          row.id,
                                          dataItem?.id,
                                          data?.change_reason
                                        );
                                      });
                                    }}
                                  >
                                    <Dust color="red" />
                                  </div>
                                )}
                                {dataItem?.name && (
                                  <p className="m-0 separator">
                                    {index < 0 ? "" : ","}
                                  </p>
                                )}
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
              ...InventoryItemColumns,

              {
                cell: (row) => {
                  return (
                    <div className="flex-grow four h-[126px]">
                      <div className="flex justify-end gap-[4px] meta mb-[12px]">
                        {common?.permissions?.["Inventory Management"]
                          ?.write && (
                          <Button
                            icon={<Add color="#775FD5" />}
                            color="text-white"
                            bg="bg-bgActionDots"
                            className="min-w-[28px] h-[28px] p-0 flex items-center justify-center flex-shrink-0"
                            cta={async () => {
                              if (row.itemCategory?.name === "H-Kit") {
                                const row1 = builder?.allTemplates?.filter(
                                  (kit) => kit.id === row?.kitId
                                )?.[0];
                                if (row1) {
                                  dispatch(editFormReducer(row1));
                                }
                                // inventoryKitBuilder[0].initialValue = row.name;
                                setformName(`Build Kit`);
                                // setformName(`${row.name} Kit Template`);
                                setFormIcon(<AddInventoryPurple />);

                                dispatch(
                                  setForm({
                                    state: true,
                                    type: "edit-kit-template",
                                  })
                                );
                                setFormValidation(
                                  () => (error, values, store) => {
                                    quantityChcek(error, values, store);
                                  }
                                );
                                setokBtnText("Build");
                                setCancelBtnText("Cancel");
                              } else {
                                const result = await getItemCategoryByIdAction(
                                  row.itemCategory.id
                                );

                                var customEnventory = [];
                                result.data?.fields?.forEach((field, index) => {
                                  var obj = {};
                                  if (!field.isStandard) {
                                    obj.type = getType(field.format?.name);
                                    obj.name = field.id || undefined;
                                    obj.label = field.name || undefined;
                                    obj.initialValue = field.value || undefined;
                                    obj.required = field.isRequired || false;
                                    obj.placeholder =
                                      field.placeholder || field.name;
                                    obj.options = field.options || [];
                                    customEnventory.push(obj);
                                  }
                                });

                                setformName("Add Inventory");
                                setokBtnText("Save");
                                setCancelBtnText("Cancel");
                                setFormIcon(<Assemblies purple />);
                                addNewInventory[0].initialValue = row?.id;
                                addNewInventory[0].placeholder = row.name;
                                addNewInventory[0].disabled = true;
                                addNewInventory[7].initialValue = false;
                                addNewInventory[8].disabled = true;
                                addNewInventory[8].required = false;
                                addNewInventory[9].required = false;
                                addNewInventory[9].disabled = true;
                                addNewInventory[9].options =
                                  settings.systemUsers
                                    ?.filter((active) => active.isActive)
                                    ?.map((data) => {
                                      return {
                                        id: data.id,
                                        name:
                                          data.firstName + " " + data.lastName,
                                      };
                                    });
                                addNewInventory[4].required =
                                  result.data?.fields?.filter(
                                    (data) =>
                                      data.mergeFieldKey === "SerialNumber"
                                  )?.[0]?.isRequired || false;

                                addNewInventory[5].required =
                                  result.data?.fields?.filter(
                                    (data) => data.mergeFieldKey === "Barcode"
                                  )?.[0]?.isRequired || false;

                                addNewInventory[7].required =
                                  result.data?.fields?.filter(
                                    (data) =>
                                      data.mergeFieldKey ===
                                      "CloseToExpiryAlert"
                                  )?.[0]?.isRequired || false;
                                addNewInventory[7].initialValue =
                                  result.data?.fields?.filter(
                                    (data) =>
                                      data.mergeFieldKey ===
                                      "CloseToExpiryAlert"
                                  )?.[0]?.isRequired || false;
                                addNewInventory[7].disabled =
                                  !result.data?.fields?.filter(
                                    (data) =>
                                      data.mergeFieldKey ===
                                      "CloseToExpiryAlert"
                                  )?.[0]?.isRequired || false;

                                addNewInventory[8].required =
                                  result.data?.fields?.filter(
                                    (data) =>
                                      data.mergeFieldKey ===
                                      "CloseToExpiryAlert"
                                  )?.[0]?.isRequired || false;
                                addNewInventory[8].disabled =
                                  !result.data?.fields?.filter(
                                    (data) =>
                                      data.mergeFieldKey ===
                                      "CloseToExpiryAlert"
                                  )?.[0]?.isRequired || false;

                                addNewInventory[3].required =
                                  result.data?.fields?.filter(
                                    (data) =>
                                      data.mergeFieldKey === "ExpirationDate"
                                  )?.[0]?.isRequired || false;

                                setUpdatedData([
                                  ...addNewInventory,
                                  ...customEnventory,
                                ]);
                                addInventoryForItemCTA();
                                dispatch(
                                  setForm({
                                    state: true,
                                    type: "addInventory",
                                  })
                                );
                              }
                            }}
                          />
                        )}
                        <NavDropdown
                          title={
                            <Button
                              icon={<DotsIcon />}
                              className="min-w-[28px] h-[28px] p-0 flex items-center justify-center flex-shrink-0"
                              color="text-white"
                              bg="bg-bgActionDots"
                            />
                          }
                          className="hema-ellipse-dropdown"
                          disabled={
                            !(
                              common?.permissions?.["Inventory Management"]
                                .update ||
                              common?.permissions?.["Inventory Management"]
                                .delete
                            )
                          }
                          // id="navbarScrollingDropdown"
                        >
                          {common?.permissions?.["Inventory Management"]
                            ?.update && (
                            <NavDropdown.Item
                              className="mb-1"
                              onClick={async () => {
                                const getAllUsers = await getItemByIdAction(
                                  row.id
                                );
                                createNewItem[0].initialValue = row?.name;
                                createNewItem[0].disabled = true;
                                createNewItem[1].initialValue =
                                  row?.itemCategory?.id;
                                createNewItem[1].placeholder =
                                  row?.itemCategory?.name;
                                createNewItem[1].disabled = true;
                                createNewItem[2].initialValue =
                                  row?.description;
                                createNewItem[2].required =
                                  row?.itemCategory?.name === "H-Kit"
                                    ? true
                                    : false;
                                createNewItem[3].storageUrl =
                                  row?.image != null
                                    ? row?.image?.storageUrl
                                    : undefined;
                                createNewItem[4].options = common?.allTypes;
                                createNewItem[4].initialValue =
                                  row?.unitType?.id;
                                createNewItem[4].placeholder =
                                  "Select Unit Type";
                                createNewItem[5].initialValue = row?.unitSize;
                                createNewItem[6].initialValue =
                                  row?.lowInventoryThreshold ? true : false;
                                createNewItem[7].initialValue =
                                  row.lowInventoryThreshold;
                                createNewItem[7].disabled =
                                  row.lowInventoryThreshold ? false : true;
                                createNewItem[8].disabled =
                                  row.lowInventoryThreshold ? false : true;
                                if (getAllUsers.status === 200) {
                                  createNewItem[8].initialValue =
                                    getAllUsers?.data?.users;
                                }
                                createNewItem[8].options = settings.systemUsers
                                  ?.filter((active) => active.isActive)
                                  ?.map((data) => {
                                    return {
                                      id: data.id,
                                      name:
                                        data.firstName + " " + data.lastName,
                                    };
                                  });
                                setUpdatedData(createNewItem);
                                setformName("Edit Item");
                                setFormIcon(<ExpiryAlertPurple />);
                                setokBtnIcon(<Confirm />);
                                setokBtnText("Save");
                                setCancelBtnText("Cancel");
                                dispatch(editFormReducer(row));
                                addInventoryCTA(row.id);
                                dispatch(
                                  setForm({ state: true, type: "editItem" })
                                );
                              }}
                            >
                              <div className="flex gap-[13px] items-center">
                                <Edit color="#775FD5" />
                                <HemaValue text="Edit" />
                              </div>
                            </NavDropdown.Item>
                          )}
                          {common?.permissions?.["Inventory Management"]
                            ?.delete && (
                            <NavDropdown.Item
                              className="mb-1"
                              cta={() => {
                                Remove[0].label = "Assembly Name";
                                Remove[0].initialValue = row?.item?.name;
                                setUpdatedData(Remove);
                                setformName("Delete Assembly Item");
                                setFormIcon(<DeletePurple />);
                                dispatch(
                                  setForm({
                                    state: true,
                                    type: "deleteItem",
                                  })
                                );
                              }}
                              onClick={() => {
                                Remove[0].label = "Item Name";
                                Remove[0].initialValue = row?.name;
                                setformName("Delete Item");
                                setFormIcon(<DeletePurple />);
                                setokBtnIcon(<Confirm />);
                                setokBtnText("Confirm");
                                setCancelBtnText("Cancel");
                                dispatch(
                                  setForm({
                                    state: true,
                                    type: "deleteItem",
                                  })
                                );
                                setUpdatedData(Remove);
                                setCTA(() => async (data) => {
                                  deleteInventoryForItemCTA(
                                    row.id,
                                    row.name,
                                    data?.change_reason
                                  );
                                });
                              }}
                            >
                              <div className="flex gap-[16px] items-center">
                                <DeleteIcon />
                                <HemaValue text="Delete" />
                              </div>
                            </NavDropdown.Item>
                          )}
                          {common?.permissions?.["Inventory Management"]
                            ?.update && (
                            <NavDropdown.Item
                              className="mb-1"
                              onClick={() => {
                                dispatch(editFormReducer(row));
                                createNewItemVersion[0].options =
                                  common?.allInventorylanguages;
                                createNewItemVersion[0].disabled = false;
                                createNewItemVersion[0].initialValue = "";
                                createNewItemVersion[0].placeholder =
                                  "Select Language";
                                createNewItemVersion[1].initialValue = row.name;
                                createNewItemVersion[2].initialValue =
                                  row.description;
                                createNewItemVersion[2].required =
                                  row?.itemCategory?.name === "H-Kit"
                                    ? true
                                    : false;
                                createNewItemVersion[2].placeholder =
                                  "Enter description";
                                createNewItemVersion[3].disabled = true;
                                createNewItemVersion[4].disabled = true;

                                createNewItemVersion[5].disabled = true;
                                createNewItemVersion[3].placeholder =
                                  row.itemCategory?.name;
                                createNewItemVersion[3].initialValue =
                                  row.itemCategory?.id;
                                createNewItemVersion[4].placeholder =
                                  row.unitType?.name || "Select Unit Type ";
                                createNewItemVersion[5].initialValue =
                                  row.unitSize;
                                setFormValidation(() => (errors, values) => {
                                  if (values?.name?.length > 100) {
                                    errors.name = "max 100 character allowed";
                                  }
                                  if (values?.description?.length > 500) {
                                    errors.description =
                                      "max 500 character allowed";
                                  }
                                  return errors;
                                });
                                setUpdatedData(
                                  createNewItemVersion?.filter(
                                    (rem) => rem.name !== "change_reason"
                                  )
                                );
                                setformName("Create New Translation");
                                setFormIcon(<CreateTranslation />);
                                setokBtnIcon(<Confirm />);
                                setokBtnText("Save");
                                setCancelBtnText("Cancel");
                                dispatch(
                                  setForm({ state: true, type: "editversion" })
                                );
                                addVersionCTA(row.id);
                              }}
                            >
                              <div className="flex gap-[10px] items-center">
                                <TranslateIcon />
                                <HemaValue text="Translate" />
                              </div>
                            </NavDropdown.Item>
                          )}
                        </NavDropdown>
                      </div>
                    </div>
                  );
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ]}
            pagination={true}
            paginationComponentOptions={{
              selectAllRowsItem: true,
              selectAllRowsItemText: "ALL",
              rowsPerPageText: "Showing",
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />;
            }}
            customStyles={{
              table: {
                style: { background: "transparent !important", border: "none" },
              },
              responsiveWrapper: {
                style: { overflow: "visible !important" },
              },

              head: { style: { display: "none" } },
              cells: {
                style: {
                  flexGrow: "1 !important",
                  justifyContent: "left !important",
                },
              },
              rows: {
                style: {
                  padding: "9px 16px",
                  borderRadius: "5px",
                  marginBottom: "10px",
                },
              },
            }}
          />
        ) : (
          <AlertBootstrap variant="warning" className="text-center">
            There are no records to display
          </AlertBootstrap>
        )
      ) : (
        <>
          <br />
          <Skeleton count={4} />
          <br />
          <Skeleton count={4} />
          <br />
          <Skeleton count={4} />
        </>
      )}

      {uisettings?.openform && (
        <FormContainer
          cta={CTA}
          formType={updatedData}
          formName={formName}
          icon={formIcon}
          formValidation={formValidation}
          setUpdatedData={setUpdatedData}
          setCTA={setCTA}
          cancelBtnIcon={cancelBtnIcon}
          cancelBtnText={cancelBtnText}
          okBtnIcon={okBtnIcon}
          okBtnText={okBtnText}
          setokBtnIcon={setokBtnIcon}
          setokBtnText={setokBtnText}
          callCreateNewItem={callCreateNewItem}
        />
      )}
    </>
  );
};

export default Inventory;
